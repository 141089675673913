import type { NonEmptyArray } from '../../../utils'

import { BufferType } from '../../../domain'

// 100 kb
export const MAX_LENGTH = 100 * 1_024

export const parsePayload = (buffer: Buffer): [serializedPayload: string, possibleTypes: NonEmptyArray<BufferType>] => {
    if (buffer.length > MAX_LENGTH) {
        /** Limit by size and propagate limitation signal through LARGE_BUFFER */
        return [buffer.toString(undefined, 0, MAX_LENGTH), [BufferType.LARGE_BUFFER]]
    }

    const asString = buffer.toString()

    if (!asString) {
        return [asString, [BufferType.STRING, BufferType.HEX]]
    }

    if (!isNaN(Number(asString))) {
        return [asString, [BufferType.NUMBER, BufferType.STRING, BufferType.HEX]]
    }

    try {
        switch (typeof JSON.parse(asString)) {
            case 'object':
                return [asString, [BufferType.OBJECT]]
            default:
                return [asString, [BufferType.STRING, BufferType.HEX]]
        }
    } catch (_: unknown) {
        return [asString, [BufferType.STRING, BufferType.HEX]]
    }
}
